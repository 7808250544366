<template>
  <div class="container">
    <div class="row mt-md-3">
      <div class="col-md-8 mx-auto">
        <div class="card border border-0 shadow-none">
          <div class="card-body px-md-5 py-md-3">
            <div v-if="!tfaToken">
              <div>
                <div class="row mb-4">
                  <div class="col-auto mx-auto text-center">
                    <img
                      src="@/assets/images/brand/full-logo.svg"
                      alt="Swandoola"
                      width="300"
                      class="mb-4"
                    />
                    <h3 class="font-weight-bold">
                      Secure Clients Portal Login
                    </h3>
                  </div>
                </div>
              </div>

              <loading v-if="busy" ></loading>
              
              <form @submit.prevent="checkEmailForClinics " v-if="!requested && !showClinicSelection">
                <div class="row mb-3">
                  <div class="col my-auto">
                    <label for=""
                      >Enter your email address to request a secure login
                      link</label
                    >
                    <input
                      id="email"
                      type="email"
                      class="form-control email-input"
                      name="email"
                      required
                      autocomplete="email"
                      autofocus
                      placeholder="jane@example.com"
                      v-model="form.email"
                    />
                  </div>
                </div>

                <div class="row mt-3 mb-4 text-center">
                  <div class="col-md-6 mx-auto">
                    <button
                      type="submit"
                      class="btn btn-outline-primary btn-lg btn-block"
                      :disabled="busy"
                    >
                      <i
                        class="fad mr-2"
                        :class="busy ? 'fa-spin fa-spinner' : 'fa-key'"
                      ></i>
                      Request Login Link
                    </button>
                  </div>
                </div>
              </form>


               <template v-if="showClinicSelection && !requested && !busy">
                <div class="row mb-3">
                  <div class="col">
                    <p
                        class="alert alert-primary"
                    >We have detected that you have relations with multiple clinics, please select the clinic you would like to login to.</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8">
                    <select v-model="form.clinic_id" class="form-control">
                      <option value>Select a clinic</option>
                      <option v-for="c in clinics" :key="c.id" :value="c.id">{{ c.name }}</option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <button class="btn btn-primary" @click="requestLoginToken">
                      <i class="fad fa-check mr-2"></i>Request Link
                    </button>
                  </div>
                </div>
              </template>

              </div>

              <div class="row" v-if="!requested && !showClinicSelection">
                <div class="col">
                  <p class="px-md-4 text-black">
                    <span class="font-weight-bold"
                      >No password, wait, what?</span
                    ><br />That’s right, no password! When you want to login to
                    Swandoola, we send you an email that has a special login
                    link. Clicking on that link will sign you in. That’s all
                    there is to it.
                    <br />
                    Alternatively you can use your Google account to login.
                  </p>
                  <p class="mt-3 px-md-4">
                    <a
                      href="https://support.swandoola.com/passwordless"
                      class="text-primary"
                      target="_blank"
                      >Click here to find out more about Swandoola security and
                      why we use password-less authentication.</a
                    >
                  </p>
                </div>
              </div>

              <div v-if="requested">
                <div class="row mb-3">
                  <div class="col">
                    <h5>Method One: Magic Link</h5>
                    <p class="alert alert-info">
                      <i class="fad fa-info mr-2"></i>
                      We've sent you an email that contains a secure login link.
                      You may close this tab and follow that link to securely
                      gain access.
                    </p>
                  </div>
                </div>
                <form @submit.prevent="loginWithOtp">
                  <div class="row">
                    <div class="col">
                      <h5>Method Two: One-Time Password</h5>
                      <p class="alert alert-info">
                        <i class="fad fa-info mr-2"></i>
                        Enter the One-Time Password contained in the email that
                        we've sent you.
                      </p>
                      <input
                        id="otp"
                        type="password"
                        class="form-control w-50 mx-auto mb-3"
                        name="otp"
                        required
                        autofocus
                        placeholder="Enter your One-Time Password..."
                        v-model="form.otp"
                      />
                    </div>
                  </div>
                  <div class="row text-center mb-3">
                    <div class="col">
                      <button class="btn btn-primary mx-auto">
                        Login with Secure OTP
                      </button>
                    </div>
                  </div>
                </form>
                <div class="row text-center">
                  <div class="col">
                    <button
                      class="btn btn-sm btn-info"
                      v-if="!smsSent"
                      :disabled="busy"
                      @click="requestSmsOtp"
                    >
                      Request SMS
                    </button>
                    <div v-if="smsSent" class="rounded alert alert-info mt-2">
                      <div>
                        Thank you, your SMS is on it's way. If you have
                        registered a mobile number with us you will receive an
                        SMS with your One-Time Password to enter above.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="tfaToken" class="mb-3">
              <div class="row">
                <div class="col">
                  <div class="alert alert-info" role="alert">
                    <h4 class="alert-heading">Two-Factor Authentication</h4>
                    <p>
                      We have sent you a secure code via SMS. Please enter the
                      secure code into the box below to gain access. This code
                      will expire in 5 minutes.
                    </p>
                    <hr />
                    <p class="mb-0">
                      You are seeing this because you require 2FA in your User
                      Settings.
                    </p>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col text-center">
                  <form @submit.prevent="authorize2fa">
                    <input
                      type="number"
                      v-model="tfaCode"
                      placeholder="000000"
                      class="form-control w-25 mx-auto text-center mb-3"
                      required
                    />
                    <button type="submit" class="btn btn-primary">
                      Authorize 2FA
                    </button>
                  </form>
                </div>
              </div>
            </div>

            <div class="row" v-if="error">
              <div class="col">
                <p class="alert alert-danger">
                  <i class="fad fa-info mr-2"></i>
                  {{ error }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/styles/tailwind.css";
import SuccessAlert from "../components/alerts/Success";
import ErrorAlert from "../components/alerts/Error";
import Loading from "../components/loaders/Loading";

export default {
  data() {
    return {
      form: {
        email: "",
        otp: "",
        requester: "clients-portal",
        clinic_id: null,
      },
      clinic: [],
      showNotFound: false,
      showClinicSelection: false,
      //
      requested: false,
      error: null,
      tfaToken: "",
      tfaCode: "",
      busy: false,
      smsSent: false,
    };
  },
  methods: {
    checkEmailForClinics() {
      if (this.form.email.length > 3) {
        this.$axios
          .post(process.env.VUE_APP_API_URL + "/internal/check-login-clinics", {
            email: this.form.email,
          })
          .then(({ data }) => {
            this.clinics = data;
            if (this.clinics.length == 0) {
              this.showNotFound = true;
              this.form.email = "";
            } else if (this.clinics.length == 1) {
              return this.requestLoginToken();
            } else {
              return (this.showClinicSelection = true);
            }
          });
      }
    },
    requestLoginToken() {
      this.busy = true;
      this.error = null;
      this.requested = false;
      this.$axios
        .post(
          process.env.VUE_APP_API_URL + "/internal/request-login-token",
          this.form
        )
        .then(({ data }) => {
          console.log(data);
          if (data.error) {
            this.error = data.error;
          } else {
            this.requested = true;
          }
          this.busy = false;
        });
    },
    requestSmsOtp() {
      this.busy = true;
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/request-sms-otp", this.form)
        .then(({ data }) => {
          this.busy = false;
          this.smsSent = true;
          this.$EventBus.$emit("alert", data);
        });
    },
    loginWithOtp() {
      this.busy = true;
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/login-otp", this.form)
        .then(({ data }) => {
          if (data.error == "invalid-otp") {
            this.error = "Invalid OTP";
          } else if (data.error == "tfa_required") {
            this.$router.push(data.url);
          } else if (!data.error) {
            this.$store.user = data.user;
            this.$store.token = data.token;
            localStorage.setItem("user", JSON.stringify(data));
            if (data.token) {
              this.$axios.defaults.headers.Authorization = `Bearer ${data.token.access_token}`;
            }
            this.$router.push({ name: "DashboardView" });
          }
          this.busy = false;
        });
    },
    authorize2fa() {
      this.busy = true;
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/login-2fa", {
          token: this.tfaToken,
          code: this.tfaCode,
        })
        .then(({ data }) => {
          if (data.error == "invalid-credentials") {
            this.error = "Invalid code";
          } else if (data.error == "invalid-2fa") {
            this.error = "Invalid 2FA Code";
          } else if (!data.error) {
            this.$store.user = data.user;
            this.$store.token = data.token;
            localStorage.setItem("user", JSON.stringify(data));
            if (data.token) {
              this.$axios.defaults.headers.Authorization = `Bearer ${data.token.access_token}`;
            }
            this.$router.push({ name: "DashboardView" });
          }
          this.busy = false;
        });
    },
    getParams() {
      const params = new URLSearchParams(window.location.search);
      if (params.has("require2fa")) {
        this.tfaToken = params.get("require2fa");
      }
    },
  },
  mounted() {
    this.getParams();
  },
  components: {
    SuccessAlert,
    ErrorAlert,
    Loading,
  },
};
</script>

<style lang="scss">
.email-input {
  border: 2px solid #03006b;
  border-radius: 1.25rem;
  padding: 2rem;
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.email-input {
  color: #03006b;
}

.email-input:focus {
  color: #03006b;
  outline: none !important;
}
</style>
